import React,{ useEffect,useState } from 'react'
import { useLocation } from "@reach/router"
// import styled from "styled-components"; 

import SEOMetaData from '../../components/background/SEOMetaData.js';
import {InterestTracker} from "../../components/background/InterestTracker.js";
// import GoogleAnalyticsEvent from "../../components/background/GoogleAnalyticsEvent.js";
// import { OutboundLink } from "gatsby-plugin-google-gtag"

import Tier3Layout from "../../components/content/Tier3Layout"
// import ArticleSidebar from "../../components/content/ArticleSidebar"
// import ConsultationRequestTrigger from "../../components/forms/ConsultationRequestTrigger"
// import SampleItineraries from "../../components/content/SampleItineraries"
// import PageSectionHeadline from "../../components/content/PageSectionHeadline"

// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
// import Carousel from 'react-bootstrap/Carousel'
// import Button from 'react-bootstrap/Button'
// import Modal from 'react-bootstrap/Modal'

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSearchPlus } from '@fortawesome/free-solid-svg-icons'
// import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
// import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons'
// import { faChevronDoubleLeft } from '@fortawesome/pro-solid-svg-icons'

import '../../styles/tier3.css'
// import headerimage from '../../images/headerimage-spiritualrestoration.jpg'




const WebsiteTerms = () => {
    const pageData = {
                        headline:{
                            text:<>Website Terms of Use</>,
                            id:'websiteterms'
                        },
                        sectionnav:{section:'agreements'},
                        seo:{
                            title:'Website Terms of Use',
                            description:'',
                            // image:'/images/headerimage-spiritualrestoration.jpg'
                        }
                    }; 


    let location = useLocation(); 
    useEffect(() => {
        InterestTracker('TnC Master Agreement',location); 
    },[location]);

    // const { search } = useLocation()
    const [showConsultationForm,setShowConsultationForm] = useState(false); 

    // function startConsultationRequest(which){ 
    //     GoogleAnalyticsEvent("start_consultationrequest",{buttonLocation:which})
    //     setShowConsultationForm(true); 
    // }
    

  return (
    <main>
        <SEOMetaData title={pageData.seo.title} description={pageData.seo.description} image={pageData.seo.image} />
        <Tier3Layout headline={pageData.headline} sectionnav={pageData.sectionnav} showConsultForm={showConsultationForm} onCloseConsultForm={()=>{setShowConsultationForm(false)}}>

            <article>


<p>Last updated September 8, 2021</p>

<h2>Agreement to Terms</h2>

<p>These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Rook Behr Ltd. dba Outward Travel ("Company," “we," “us," or “our”), concerning your access to and use of the https://www.outward.travel website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. If you do not agree with all of these terms of use, please leave the Site immediately.</p>

<p>We may change these Terms of Use at any time, for any reason, without prior notice. When changes are made, the "Last updated" date at the top of this document will reflect the date the changes were made. You will be subject to and assumed to have read and accepted the current version of these Terms of Use whenever you visit the Site.</p>

<h2>This Website is Outward Travel's Intellectual Property</h2>

<p>Unless otherwise indicated, the Site is our proprietary property and all rights are reserved pertaining to the source code, content, databases, functionality, design, text, and graphics. Photographs found on this Site are stock photography sourced from (1) stock photography websites, (2) tourism boards, or (3) travel suppliers and used in accordance with those terms. We don't claim ownership of the photographs or videos however they are used under license or permission, have been edited or tailored to their specific use on this Site, and you are prohibited from downloading them (except by browser cache) for any purpose other than reading our website content. Trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws.</p>

<p>This Site is provided AS-IS for your personal use only. No part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission. </p>

<p>Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site and its content.</p>

<h2>Website Users are Not Customers</h2>
<p>Using this Site does not make you a customer or client of Outward Travel. Only individuals who have signed a Booking Agreement and agreed to the General Terms &amp; Conditions of Travel Services are customers of Outward Travel. We have no obligation to you 
	until you have become a customer.</p>

<h2>Prohibited Activities</h2>

<ol>
    <li>You may not copy, repost, or reuse content from our Site for any commercial purpose. This includes the photographs, written content, logos, or ancillary artwork. Any commercial purpose includes use on your own website or marketing materials.</li>
<li>You may not attempt to access the underlying technology on which this Site is built, including content management systems, servers, hosting providers, etc.</li>
<li>You may not attempt ot circumvent, disable, or  interfere with security-related features of the Site.</li>
<li>You may not wrap our Site in a Frame or IFrame. You may link to our website only in a full-featured browser window (including an address bar, forward/back buttons, etc).</li>
<li>You may not attempt to "break" or disrupt the normal operations our Site or attempt to upload or transmit harmful software to our servers. </li>
<li>You may not enter false information in any form, or impersonate any other person. You may not use our site to send unsolicited email to people you do not already know.</li>
<li>Except when part of standard search engine indexing, you may not use automated software to access our site, monitor for changes, or download content. </li>
<li>You may not flood our servers with excessive traffic for the purposes of taking it offline or disrupting other users' access to the site.</li>
</ol>

<h2>Feedback and Suggestions</h2>

<p>Any feedback or suggestions you send to us regarding the Site are non-confidential and become our sole property. We will 
	owe you no attribution, compensation, or license should we implement your suggestions. </p>

<h2>Our Right to Monitor the Site and Enforce These Terms of Use</h2>

<p>We reserve the right to monitor the Site for violations of these Terms of Use and take legal action against anyone who violates them. We reserve the right to monitor other websites for copyright violations of our Site's content and take legal action against anyone violating our copyright or the copyright of those we've legally obtained content from. We reserve the right to block access to this site to any individual, company, region, government, or country for any reason. </p>

<h2>Our Right to Change the Website At Any Time</h2>
<p>We reserve the right to change the site at any time without notification, including changing of URLs with or without redirection. We reserve the right to add or remove functionality that may fundamentally alter your experience or use of the site at any time.</p>
<p>Content on this Site may become out of date or may become inaccurate, or may not be accurate for all users in all regions. We do not have the obligation to remove inaccurate or out-of-date content There may be information on this Site that contains 
	errors or omissions, including information related to photographic representation of travel opportunities, descriptions, pricing, or any other information. We reserve the right to correct errors without prior notice. You may not hold us liable for inaccurate information.</p>
<p>We cannot guarantee availability of the Site. We may experience system, server, or programming errors that result in the 
	Site becoming unavailable. We do not guarantee the site is compatible with your device(s), networks, or Internet connection. Nothing in these Terms of Use implies an obligation to keep the Site available for any length of time.</p> 

<h2>No Ownership or Endorsement of Third Party Websites</h2>
<p>This Site may link to other, third-party websites not owned or managed by Outward Travel. Outward Travel does not endorse or guarantee the accuracy of any information on any other website. You follow links to other websites at your own risk.</p>

<h2>Collection of Data</h2>
<p>We reserve the right to collect data about the individuals or entities that use this Site. Data collected may include or be attached to personally identifiable information when you submit a form on this Site. We also collect aggregated, de-identified metrics that is used to study, inform, analyze, and evaluate the effectiveness of our website and marketing strategies. Our 
	right to collect information does not interfere with your rights to privacy or your rights to request an audit or deletion of 
	data collected about you. Please review our Privacy Policy for full details.</p>
	
<h2>Disclaimer of Warranty, Limitation of Liability, Indemnification</h2>

<p>(A) YOU EXPRESSLY AGREE THAT USE OF THE SITE IS AT YOUR SOLE RISK. NEITHER OUTWARD TRAVEL, ITS PRESENT OR FUTURE PARENT(S), SUBSIDIARIES, OR RELATED ENTITIES, NOR ANY OF THEIR RESPECTIVE EMPLOYEES, AGENTS, THIRD PARTY CONTENT PROVIDERS OR LICENSORS WARRANT THAT THE SITE WILL BE UNINTERRUPTED OR ERROR FREE; NOR DO THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED FROM USE OF THE SITE, OR AS TO THE ACCURACY, RELIABILITY OR CONTENT OF ANY INFORMATION, SERVICE, OR MERCHANDISE PROVIDED THROUGH THE SITE.</p>

<p>(B) THE SITE, INCLUDING, WITHOUT LIMITATION, ANY DOWNLOADABLE SOFTWARE, DOCUMENT, OR OTHER PACKAGE, IS PROVIDED ON AN "AS IS" BASIS WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OTHER THAN THOSE WARRANTIES WHICH ARE IMPLIED BY AND INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THESE TERMS OF USE.</p>

<p>(C) TO THE FULLEST EXTENT PERMISSIBLE BY APPLICABLE LAW, IN NO EVENT SHALL OUTWARD TRAVEL BE LIABLE TO YOU FOR ANY PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, COST OF SUBSTITUTE GOODS OR SERVICES, LOSS OF DATA, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER AND/OR DEVICE OR TECHNOLOGY FAILURE OR MALFUNCTION OR FOR ANY FORM OF DIRECT OR INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES BASED ON ANY CAUSES OF ACTION ARISING OUT OF USE OF THE SITE OR ANY ALLEGED FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, OR DELAY IN SERVICE, OPERATION, OR TRANSMISSION OF THE SITE, OR ANY ALLEGED COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OF PROPERTY, AND/OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF OR POSTING OF ANY RECORD, CONTENT, OR TECHNOLOGY, PERTAINING TO OR ON THE SITES. YOU AGREE THAT THIS LIMITATION OF LIABILITY APPLIES WHEHER SUCH ALLEGATIONS ARE FOR BREACH OF CONTRACT, TORTIOUS BEHAVIOR, NEGLIGENCE, OR FALL UNDER ANY OTHER CAUSE OF ACTION, REGARDLESS OF THE BASIS UPON WHICH LIABILITY IS CLAIMED AND EVEN IF OUTWARD TRAVEL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LOSS OR DAMAGE. WITHOUT LIMITING THE GENERALITY OF THE FORGEOING, YOU ALSO SPECIFICALLY ACKNOWLEDGE THAT OUTWARD TRAVEL IS NOT LIABLE FOR ANY ACTUAL OR ALLEGED DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF OTHER USERS OF THE SITES OR ANY OTHER THIRD PARTIES.</p>

<p>IF APPLICABLE LAW DOES NOT ALLOW ALL OR ANY PART OF THE ABOVE LIMITATION OF LIABILITY TO APPLY TO YOU, THE LIMITATIONS WILL APPLY TO YOU ONLY TO THE EXTENT PERMITTED BY APPLICABLE LAW.</p>

<p>(D) OUTWARD TRAVEL DISCLAIMS ANY AND ALL LIABILITY OF ANY KIND FOR ANY UNAUTHORIZED ACCESS TO OR USE OF YOUR PERSONALLY IDENTIFIABLE INFORMATION. BY ACCESSING THE SITE, YOU ACKNOWLEDGE AND AGREE TO OUTWARD TRAVEL'S DISCLAIMER OF ANY SUCH LIABILITY. IF YOU DO NOT AGREE, YOU SHOULD NOT ACCESS OR USE THE SITE.</p>

<p>(F) TO THE EXTENT PERMITTED BY APPLICABLE LAW, ANY DISPUTE, CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING IN ANY WAY TO THE SERVICE OR YOUR USE OF THE SERVICE AND/OR SITE, THESE TERMS OF USE, OR THE RELATIONSHIP BETWEEN US, MUST BE COMMENCED WITHIN ONE YEAR OF THE RELEVANT EVENTS. A DISPUTE IS COMMENCED IF IT IS FILED IN AN ARBITRATION OR, IF THE DISPUTE IS NON-ARBITRABLE, A COURT WITH JURISDICTION, DURING THE ONE-YEAR PERIOD. IF YOU OR WE PROVIDE NOTICE OF A DISPUTE UNDER THE SECTION LABELED "DISPUTE RESOLUTION")", THE ONE-YEAR PERIOD IS TOLLED FOR 60 DAYS FOLLOWING RECEIPT OF THE NOTICE OF DISPUTE. YOU AND WE EACH WAIVE—THAT IS, GIVE UP—THE RIGHT TO PURSUE ANY DISPUTE, CLAIM OR CONTROVERSY THAT IS NOT FILED WITHIN ONE YEAR AND ANY RIGHT YOU OR WE MAY HAVE HAD TO PURSUE THAT DISPUTE, CLAIM OR CONTROVERSY IN ANY FORUM IS PERMANENTLY BARRED.</p>

<p>You agree to defend, indemnify and hold harmless Outward Travel, its affiliates and their respective directors, officers, employees and agents from and against all claims and expenses, including attorneys' fees, arising out of the use of the Site by you or your Account. Outward Travel reserves the right to take over the exclusive defense of any claim for which we are entitled to indemnification under this Section. In such event, you shall provide Outward Travel with such cooperation as is reasonably requested by Outward Travel.</p>


<h2>Governing Law &amp; Venue</h2>

<p>These Terms shall be governed by and defined following the laws of State of Colorado. Outward Travel and yourself irrevocably consent that the courts of Colorado shall have exclusive jurisdiction to resolve any dispute which may arise in connection with these terms.</p>

<h2>Severability and No Implied Waiver</h2>
<p>If any provision of this agreement shall be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from this agreement and shall not affect the validity and enforceability of any remaining provisions.</p>

<p>The failure of Outward Travel to act with respect to a breach of these Terms of Use by you, or others, does not waive its right to act with respect to subsequent or similar breaches. Outward Travel does not guarantee it will take action against all breaches of this these Terms of Use.</p>

<h2>Dispute Resolution</h2>

<p>Any claim or dispute arising from or in connection with this website shall by resolved by arbitration administered by 
     Colorado Mediators and Arbitrators. Any award by the arbitrator(s) may be entered as a judgment in any court 
     having jurisdiction. Each party will pay its own Attorney’s Fees and an equal share of the costs of arbitration.</p>

<h2>Method of Communications and Waiver of Signature</h2>
<p>Regardless of the method used to contact Outward Travel, including for legal action, Outward Travel reserves the right to 
	use any method to reply including email or phone contact. By contacting Outward Travel, including by submitting any form on our website, you consent to receiving electronic and telephonic communications from us. You hereby waive any right or requirement under any law or regulation in any jurisdiction which require original signature or non-electronic records.</p> 

<p>By accessing this Site on any computing device, or by reading material printed from this Site, you are agreeing to these Terms of Use and waive any requirement of electronic or handwritten signature. If you do not agree, discontinue using the Site immediately.</p>

<h2>Contact Information</h2>

<p>To inquire about these terms or to resolve a complaint about this Site, please contact us via email at go@outward.travel.</p>

            </article>

        </Tier3Layout>
    </main>
  )
}


export default WebsiteTerms;

